import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aside = makeShortcode("Aside");
const Code = makeShortcode("Code");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Description`}</h2>
    <p>{`You don't need to run an online Certificate Authority to create certificates and perform basic crypto operations using the `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI tool. This document gives some examples of things you can do with the `}<inlineCode parentName="p">{`step`}</inlineCode>{` command by itself.`}</p>
    <h2>{`Overview`}</h2>
    <p>{`Here's a few common uses of the `}<inlineCode parentName="p">{`step`}</inlineCode>{` command:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-and-work-with-x509-certificates"
        }}>{`Create and work with X.509 certificates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#get-a-tls-certificate-from-lets-encrypt"
        }}>{`Get a TLS Certificate From Let's Encrypt`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#generate-json-web-tokens-jwts-and-json-web-keys-jwks"
        }}>{`Generate JSON Web Tokens (JWTs) and JSON Web Keys (JWKs)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#obtain-and-work-with-oauth-tokens"
        }}>{`Obtain and Work With OAuth Tokens`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#miscellaneous-tools-totp-tokens-nacl-ssh-certificates"
        }}>{`Miscellaneous Tools (TOTP Tokens, NaCl, SSH Certificates)`}</a></li>
    </ul>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli"
        }}><inlineCode parentName="a">{`step`}</inlineCode></a></li>
    </ul>
    <h2>{`Create and work with X.509 certificates`}</h2>
    <p>{`Let's take a look at the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/"
      }}><inlineCode parentName="a">{`step certificate`}</inlineCode></a>{` command group.
This command group is a Swiss Army knife for working with certificates.
You can use it to create certificate signing requests (CSRs),
sign CSRs,
create self-signed certificates (e.g., a root certificate authority),
create leaf or intermediate CA certificates,
validate and inspect certificates,
renew certificates,
generate certificate bundles,
and to key-wrap private keys.`}</p>
    <p>{`Shall we try it out?`}</p>
    <h3>{`Create a Certificate Authority`}</h3>
    <p>{`Create a Root CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate create --profile root-ca "Example Root CA" root_ca.crt root_ca.key
`}</code></pre>
    <p>{`Typically you will also want an intermediate CA. Create an Intermediate CA that is signed by your Root CA:`}</p>
    <pre><code parentName="pre" {...{}}>{`step certificate create "Example Intermediate CA 1" \\
    intermediate_ca.crt intermediate_ca.key \\
    --profile intermediate-ca --ca ./root_ca.crt --ca-key ./root_ca.key
`}</code></pre>
    <p>{`Now you can store your `}<inlineCode parentName="p">{`root_ca.key`}</inlineCode>{` in a safe place offline, because you'll only need the intermediate CA key to issue TLS leaf certificates.`}</p>
    <h3>{`Issue a Leaf Certificate Bundle`}</h3>
    <p>{`Use your intermediate CA to sign leaf (end entity) certificates for your servers.`}</p>
    <p>{`Create a leaf TLS certificate for `}<inlineCode parentName="p">{`example.com`}</inlineCode>{`, valid for a year:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate create example.com example.com.crt example.com.key \\\\
    --profile leaf --not-after=8760h \\\\
    --ca ./intermediate_ca.crt --ca-key ./intermediate_ca.key --bundle
`}</code></pre>
    <p>{`By using the `}<inlineCode parentName="p">{`--bundle`}</inlineCode>{` flag we automatically bundle the new leaf certificate
with the signing intermediate certificate. TLS-based services will require the
bundle in order to verify the full chain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate verify example.com.crt --roots root_ca.crt
(no output - certificate is valid)
`}</code></pre>
    <Aside mdxType="Aside">
To get TLS working from here, you'll need to do two things:
      <ul>
        <li>Install the leaf certificate bundle <Code mdxType="Code">example.com-bundle.crt</Code> into your server configuration</li>
        <li>Get all of your clients to trust the <Code mdxType="Code">root_ca.crt</Code> certificate. You can install the certificate into the system trust store by running <Code mdxType="Code">step certificate install root_ca.crt</Code></li>
      </ul>
    </Aside>
    <h3>{`Inspect an X.509 Certificate`}</h3>
    <p>{`To inspect the certificate you just made, run:`}</p>
    <CodeBlock language="shell-session" copyText="step certificate inspect example.com.crt --short" mdxType="CodeBlock">
      {`$ step certificate inspect example.com.crt --short
  Subject:     example.com
  Issuer:      Example Intermediate CA 1
  Valid from:  2020-09-02T20:48:41Z
          to:  2021-09-02T20:48:40Z`}
    </CodeBlock>
    <p>{`You can get a certificate in JSON format by calling `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/inspect"
      }}><inlineCode parentName="a">{`step certificate inspect`}</inlineCode></a>{` with `}<inlineCode parentName="p">{`--format json`}</inlineCode>{`. This example `}<a parentName="p" {...{
        "href": "https://stedolan.github.io/jq/"
      }}>{`uses jq`}</a>{` to parse the JSON and extract a specific value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect example.com.crt --format json | jq -r .validity.end
2020-09-03T20:48:41Z
`}</code></pre>
    <p>{`You can also inspect the TLS certificate for any URL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect https://smallstep.com --format json | jq -r .validity.end
2020-11-13T07:09:47Z
`}</code></pre>
    <h2>{`Get a TLS Certificate From Let's Encrypt`}</h2>
    <p><inlineCode parentName="p">{`step`}</inlineCode>{` is a full-fledged ACME client (the protocol used by `}<a parentName="p" {...{
        "href": "https://letsencrypt.org/"
      }}>{`Let's Encrypt`}</a>{`. Unlike other ACME clients, `}<inlineCode parentName="p">{`step`}</inlineCode>{` connects to a configured `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` daemon by default. To use it with Let's Encrypt or another ACME server instead, you can pass an `}<inlineCode parentName="p">{`--acme`}</inlineCode>{` endpoint:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca certificate example.com example.com.crt example.com.key \\
    --acme https://acme-v02.api.letsencrypt.org/directory
`}</code></pre>
    <p>{`This command will:`}</p>
    <ul>
      <li parentName="ul">{`Request a SSL certificate from Let's Encrypt and receive a challenge token in response`}</li>
      <li parentName="ul">{`Start up a standalone HTTP server on port 80 that serves the `}<a parentName="li" {...{
          "href": "https://letsencrypt.org/docs/challenge-types/"
        }}><inlineCode parentName="a">{`http-01`}</inlineCode>{` challenge response`}</a>{` at `}<inlineCode parentName="li">{`/.well-known/acme-challenge/<TOKEN>`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Wait for Let's Encrypt to hit the HTTP server and issue a certificate`}</li>
      <li parentName="ul">{`Save the certificate and private key to `}<inlineCode parentName="li">{`example.com.crt`}</inlineCode>{` and `}<inlineCode parentName="li">{`example.com.key`}</inlineCode></li>
    </ul>
    <p>{`If you don't want `}<inlineCode parentName="p">{`step`}</inlineCode>{` to run a standalone server to respond to the ACME challenge, you can pass `}<inlineCode parentName="p">{`--webroot <path>`}</inlineCode>{` to specify a path where `}<inlineCode parentName="p">{`step`}</inlineCode>{` will place the `}<inlineCode parentName="p">{`.well-known/acme-challenge/<TOKEN>`}</inlineCode>{` token file.`}</p>
    <p>{`For a dry run, you can use Let's Encrypt's staging server URL: `}<inlineCode parentName="p">{`https://acme-staging-v02.api.letsencrypt.org/directory`}</inlineCode></p>
    <h2>{`Generate JSON Web Tokens (JWTs) and JSON Web Keys (JWKs)`}</h2>
    <p>{`The following command groups work with JOSE objects like JWTs and JWEs:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/jwt"
        }}><inlineCode parentName="a">{`step crypto jwt`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/jwk"
        }}><inlineCode parentName="a">{`step crypto jwk`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/jwe"
        }}><inlineCode parentName="a">{`step crypto jwe`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/jws"
        }}><inlineCode parentName="a">{`step crypto jws`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/jose"
        }}><inlineCode parentName="a">{`step crypto jose`}</inlineCode></a></li>
    </ul>
    <p>{`In this example, you'll create a `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7517"
      }}>{`JSON Web Key`}</a>{` (JWK), add the public key to a keyset, and sign a `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7519"
      }}>{`JSON Web Token`}</a>{` (JWT) that expires in 15 minutes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step crypto jwk create pub.json key.json
$ cat pub.json | step crypto jwk keyset add keys.json
$ JWT=$(step crypto jwt sign \\
    --key key.json \\
    --iss "issuer@example.com" \\
    --aud "audience@example.com" \\
    --sub "subject@example.com" \\
    --exp $(date -v+15M +"%s"))
`}</code></pre>
    <p>{`We can then verify the JWT and return the payload:`}</p>
    <CodeBlock language="shell-session" copyText='echo $JWT | step crypto jwt verify --jwks keys.json --iss "issuer@example.com" --aud "audience@example.com"' mdxType="CodeBlock">
      {`$ echo $JWT | step crypto jwt verify --jwks keys.json --iss "issuer@example.com" --aud "audience@example.com"
{
  "header": {
    "alg": "ES256",
    "kid": "pwNr_RwEMFPxxfpUgsSHcCEP-CVIFQ_maI9UiHljqt0",
    "typ": "JWT"
  },
  "payload": {
    "aud": "audience@example.com",
    "exp": 1599086725,
    "iat": 1599085826,
    "iss": "issuer@example.com",
    "jti": "5681b0ad0e624c2c6da4ad610e298ba4f54c2e0c8f2731698b214126c5e780c9",
    "nbf": 1599085826,
    "sub": "subject@example.com"
  },
  "signature": "wco1X1ue14D9wmgH_DXIbTZXIg_McXRMlV80O1JDPo12j8zhHIWQikYxUBvbxfME2VbNO5WRKUB9H9WjX2FlnQ"
}`}
    </CodeBlock>
    <h2>{`Obtain and Work With OAuth Tokens`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/oauth/"
      }}><inlineCode parentName="a">{`step oauth`}</inlineCode></a>{` command group supports API authorization and single sign on with OAuth and OIDC.`}</p>
    <p>{`This command group requires that you supply your own OAuth provider URL, client ID, and client secret.`}</p>
    <h3>{`Obtain OAuth OIDC Identity Tokens`}</h3>
    <p>{`In this example, we'll sign into Google and get an OIDC identity token. Replace the `}<inlineCode parentName="p">{`--client-id`}</inlineCode>{` and `}<inlineCode parentName="p">{`--client-secret`}</inlineCode>{` with your own values obtained from Google Cloud Console's `}<a parentName="p" {...{
        "href": "https://console.cloud.google.com/apis/credentials"
      }}>{`APIs & Services`}</a>{` menu.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ TOKEN=$(step oauth \\
  --provider https://accounts.google.com \\
  --client-id 1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com \\
  --client-secret udTrOT3gzrO7W9fDPgZQLfYJ \\
  --bare --oidc)
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`--provider`}</inlineCode>{` URL must have a discovery endpoint, created by appending `}<inlineCode parentName="p">{`/.well-known/openid-configuration`}</inlineCode>{` to the URL. This is a JSON file describing endpoints and keys for OpenID Connect. For example, see `}<a parentName="p" {...{
        "href": "https://accounts.google.com/.well-known/openid-configuration"
      }}><inlineCode parentName="a">{`https://accounts.google.com/.well-known/openid-configuration`}</inlineCode></a>{`.`}</p>
    <p>{`Once you have an OIDC identity token, you can verify it. The `}<inlineCode parentName="p">{`--jwks`}</inlineCode>{` value comes from the `}<inlineCode parentName="p">{`jwks_uri`}</inlineCode>{` value at the OpenID discovery endpoint.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ echo $TOKEN | step crypto jwt verify \\
  --jwks https://www.googleapis.com/oauth2/v3/certs \\
  --iss https://accounts.google.com \\
  --aud 1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com
`}</code></pre>
    <h2>{`Miscellaneous Tools (TOTP Tokens, NaCl, SSH Certificates)`}</h2>
    <h3>{`Generate TOTP Tokens for Multi-Factor Authentication (MFA)`}</h3>
    <p>{`With `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/otp"
      }}><inlineCode parentName="a">{`step crypto otp`}</inlineCode></a>{`, you can generate a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Time-based_One-time_Password_algorithm"
      }}>{`TOTP`}</a>{` token and a QR code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step crypto otp generate \\
         --issuer smallstep.com --account name@smallstep.com \\
         --qr smallstep.png > smallstep.totp
`}</code></pre>
    <p>{`Scan the QR Code using Google Authenticator, Authy, or similar software and use it to verify the TOTP token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step crypto otp verify --secret smallstep.totp
`}</code></pre>
    <h3>{`Sign and Encrypt Arbitrary Data`}</h3>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/nacl"
      }}><inlineCode parentName="a">{`step crypto nacl`}</inlineCode></a>{` command group to sign or encrypt arbitrary data for internal use. This command group uses the `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to/"
      }}>{`NaCl library`}</a>{`'s high-speed crypto primitives.`}</p>
    <h3>{`Work with SSH Certificates`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/"
      }}><inlineCode parentName="a">{`step ssh`}</inlineCode></a>{` command group is usually used in conjunction with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`'s SSH CA functionality. But not all the subcommands require an SSH CA. You can inspect an SSH certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ssh inspect < mycert.crt
-:
        Type: ecdsa-sha2-nistp256-cert-v01@openssh.com user certificate
        Public key: ECDSA-CERT SHA256:5Zu0dV5Q0DLtXTBs6UWtvNNzg1gqUVTfvogzKyPcKPs
        Signing CA: ECDSA SHA256:PxHk36T6v70LP+wfD/RB1IbmA9I4EpyLq72F0eOH5hE
        Key ID: "carl@example.com"
        Serial: 2937319031419699911
        Valid: from 2020-09-08T17:07:53 to 2020-09-09T09:08:53
        Principals:
                carl
                carl@example.com
        Critical Options: (none)
        Extensions:
                permit-X11-forwarding
                permit-agent-forwarding
                permit-port-forwarding
                permit-pty
                permit-user-rc
`}</code></pre>
    <p>{`And you can get the fingerprint of an SSH certificate or public key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ssh fingerprint < mycert.crt
256 SHA256:CQ6+r7ccb/wqoWK1ror10c44nNvvYXp2mgbPrsTmCbw c (ECDSA-CERT)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      